import { CustomerGroupId } from '~/@types/customerGroupIds';

export const customerGroups_verificationNeeded = {
  [SiteIdent.miniluDe]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
    CustomerGroupId.COMPANY,
    CustomerGroupId.STUDENT,
    CustomerGroupId.OTHER,
    CustomerGroupId.VET,
  ],
  [SiteIdent.default]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
    CustomerGroupId.COMPANY,
    CustomerGroupId.STUDENT,
    CustomerGroupId.OTHER,
    CustomerGroupId.VET,
  ],
  [SiteIdent.miniluAt]: [CustomerGroupId.STUDENT],
  [SiteIdent.miniluNl]: [CustomerGroupId.STUDENT],
};
export const customerGroups_verificationUploadPossible = {
  [SiteIdent.miniluDe]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
    CustomerGroupId.COMPANY,
    CustomerGroupId.STUDENT,
    CustomerGroupId.OTHER,
    CustomerGroupId.VET,
    CustomerGroupId.DENTIST_WITHOUT_VERIFICATION,
    CustomerGroupId.PHARMACY_WITHOUT_VERIFICATION,
    CustomerGroupId.SURGERY_WITHOUT_VERIFICATION,
    CustomerGroupId.VET_WITHOUT_VERIFICATION,
  ],
  [SiteIdent.default]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
    CustomerGroupId.COMPANY,
    CustomerGroupId.STUDENT,
    CustomerGroupId.OTHER,
    CustomerGroupId.VET,
    CustomerGroupId.DENTIST_WITHOUT_VERIFICATION,
    CustomerGroupId.PHARMACY_WITHOUT_VERIFICATION,
    CustomerGroupId.SURGERY_WITHOUT_VERIFICATION,
    CustomerGroupId.VET_WITHOUT_VERIFICATION,
  ],
  [SiteIdent.miniluAt]: [CustomerGroupId.STUDENT],
  [SiteIdent.miniluNl]: [CustomerGroupId.STUDENT],
};

export const customerGroups_drugPurchasePossible = {
  [SiteIdent.miniluDe]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
  ],
  [SiteIdent.default]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
  ],
  [SiteIdent.miniluAt]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
  ],
  [SiteIdent.miniluNl]: [
    CustomerGroupId.DENTIST,
    CustomerGroupId.SURGERY,
    CustomerGroupId.PHARMACY,
  ],
};

export default function useCustomerGroupMaps() {
  return {
    drugPurchasePossible: customerGroups_drugPurchasePossible,
    verificationNeeded: customerGroups_verificationNeeded,
    verificationUploadPossible: customerGroups_verificationUploadPossible,
  };
}
